body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/*  视频外面的容器样式 */
.videos_portal_styles {
  margin-left: 0%;
  width: 68%;
  height: calc(100vh - 10%);
  left: 0%;
  top: 0px;
  position: absolute;
  display: flex;
  flex-wrap: wrap;
}
/* 视频设置 */
#dummy_video_canvas {
  display: none;
}
/* 企业网 */
.contact{
  font-weight: 500;
  font-size: 16px;
  color: rgb(252, 250, 250);
}
/* 标题字体 */
.titleFont{
  font-weight: 500;
  font-size: 16px;
  color: rgb(12, 11, 11);
}
/* 红色 */
.red{
  color: red;
}
.fixThebottom{
  font-weight: 200;
  font-size: 10px;
  color: rgb(12, 11, 11);
}